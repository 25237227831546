<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-grid">
                <h5 class="p-col-12">{{ $t('menu.editDevice') }}</h5>
                <div class="p-field p-grid p-col-12 p-md-4">
                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('system.newName') }} </label>
                    <div class="p-col-12 p-md-5">
                        <InputText style="width: 100%" type="text" v-model="device.friendlyName" :disabled="isEditable" />
                    </div>
                </div>
                <div class="p-field p-grid p-col-12 p-md-4">
                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('system.newShop') }} </label>
                    <div class="p-col-12 p-md-5">
                        <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :disabled="isEditable" optionLabel="name" :options="shops" :placeholder="$t('dropdown.placeholder.shop')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid p-col-12 p-md-4">
                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('system.newGame') }} </label>
                    <div class="p-col-12 p-md-5">
                        <Dropdown :showClear="true" style="width: 100%" v-model="gameModel" :disabled="isEditable" optionLabel="name" :options="games" :placeholder="$t('dropdown.placeholder.game')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid p-col-12 p-mb-0 p-pb-0">
                    <Button v-show="!isEditable" @click="saveDevice()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                </div>
                <p>{{ '*' + $t('system.alerts.successDeviceSave') }}</p>
                <transition-group name="p-message" tag="div">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
import { BoApi } from '../../service/BoApi';
import settings from '../../settings/generalSettings';
export default {
    name: 'edit',
    emits: ['refresh'],
    data() {
        return {
            shopsModel: { _id: 'UNASSIGNED', name: 'UNASSIGNED', code: '' },
            games: [
                { id: 'KENO', name: 'KENO' },
                { id: 'BALLSIX', name: 'BALL SIX' },
                { id: 'LOTTO', name: 'LOTTO' },
                { id: 'BALLSIX1', name: 'BALL SIX 1' },
                { id: 'KENO1', name: 'KENO 1' },
                { id: 'DEVICENAME', name: 'DEVICE NAME' },
                { id: 'LIVE', name: 'LIVE' },
                { id: 'LMONITOR2', name: 'LIVE MONITOR 2' },
            ],
            gameModel: {},
            shops: [],
            showError: false,
            errorText: '',
            BoApi: new BoApi(),
            device: {},
            errorKeyCount: 0,
            showSuccess: false,
            successKeyCount: 0,
        };
    },
    props: {
        deviceId: {
            type: String,
        },
        deviceName: {
            type: String,
        },
        deviceInternalId: {
            type: String,
        },
        deviceProductUrl: {
            type: String,
        },
        deviceGame: {
            type: String,
        },
        deviceShopId: {
            type: String,
        },
        deviceVersion: {
            type: String,
        },
        isEditable: {
            type: Boolean,
        },
    },
    mounted() {
        this.device = {
            internalId: this.deviceInternalId,
            shopId: this.deviceShopId,
            displayedGame: this.getGameName(this.deviceProductUrl),
            friendlyName: this.deviceName,
            version: this.deviceVersion,
            id: this.deviceId,
        };
        this.BoApi.getShops('')
            .then((shopsResponse) => {
                let shopsDdlValues = [];
                for (var i = 0; i < shopsResponse.data.length; i++) {
                    let shopItem = {
                        name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                        code: shopsResponse.data[i].code,
                        _id: shopsResponse.data[i]._id,
                    };
                    shopsDdlValues.push(shopItem);
                    if (this.device.shopId == shopsResponse.data[i]._id) {
                        this.shopsModel = shopItem;
                    }
                }
                shopsDdlValues.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
                this.shops = shopsDdlValues;
                if (this.device.displayedGame == 'OTHER') {
                    this.gameModel = { id: this.deviceProductUrl, name: 'OTHER' };
                    this.games.push(this.gameModel);
                } else {
                    this.gameModel = this.games.find((g) => g.id == this.device.displayedGame);
                }
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
            });
    },
    methods: {
        saveDevice() {
            if (this.notNullOrEmptyObject(this.gameModel)) {
                this.device.displayedGame = this.gameModel.id;
                if (this.notNullOrEmptyObject(this.shopsModel)) {
                    this.device.shopId = this.shopsModel._id;
                    this.BoApi.saveDevice(this.device)
                        .then(() => {
                            setTimeout(() => this.$emit('refresh'), 1000);
                        })
                        .catch((error) => {
                            this.checkForUnauthorizedError(error);
                            this.showErrorText(error);
                        });
                } else {
                    this.showErrorText(this.$t('system.alerts.shopError'));
                }
            } else {
                this.showErrorText(this.$t('system.alerts.productError'));
            }
        },
        getGameName(productUrl) {
            var game = 'OTHER';
            if (productUrl.indexOf(settings.gameType.magnumsix) >= 0) {
                game = 'BALLSIX1';
            } else {
                if (productUrl.indexOf(settings.gameType.kenogame) >= 0) {
                    game = 'KENO1';
                } else {
                    if (productUrl.indexOf(settings.gameType.keno) >= 0) {
                        game = 'KENO';
                    } else {
                        if (productUrl.indexOf(settings.gameType.ballsix) >= 0) {
                            game = 'BALLSIX';
                        } else {
                            if (productUrl.indexOf(settings.gameType.devicename) >= 0) {
                                game = 'DEVICENAME';
                            } else {
                                if (productUrl.indexOf(settings.gameType.lotto) >= 0) {
                                    game = 'LOTTO';
                                } else {
                                    if (productUrl.indexOf(settings.gameType.live2) >= 0) {
                                        game = 'LMONITOR2';
                                    } else {
                                        if (productUrl.indexOf(settings.gameType.live) >= 0) {
                                            game = 'LIVE';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return game;
        },
        showErrorText: function (text) {
            this.showError = true;
            this.errorText = text;
            this.errorKeyCount++;
        },
    },
};
</script>


